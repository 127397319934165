import { Currency, CurrencyAmount, Token } from '@sushiswap/core-sdk'
import AssetInput from 'app/components/AssetInput'
import Button from 'app/components/Button'
import Card from 'app/components/Card'
import { CurrencyLogo } from 'app/components/CurrencyLogo'
import ExternalLink from 'app/components/ExternalLink'
import Typography from 'app/components/Typography'
import { GET_LOCK_DROP } from 'app/config/sculptor/lockdrop'
import { GET, GET_LOOPABLE } from 'app/config/sculptor/stake'
import { GET_TOKEN } from 'app/config/tokens'
import { GET_AVAR_TOKEN } from 'app/config/tokens/avar'
import { tryParseAmount } from 'app/functions'
import { ApprovalState, useApproveCallback } from 'app/hooks'
import useBorrow from 'app/hooks/sculptor/useBorrow'
import useLoop from 'app/hooks/sculptor/useLoop'
import usePrice from 'app/hooks/sculptor/usePrice'
import useReserve, { ReserveDataWithBalance } from 'app/hooks/sculptor/useReserve'
import useStakeLP from 'app/hooks/sculptor/useStakeLP'
import { useActiveWeb3React } from 'app/services/web3'
import { useCurrencyBalance } from 'app/state/wallet/hooks'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { currencyAmountToBigNumberJS, format } from 'lib/formatter'
import { healthFactorColorClass } from 'lib/health-factor'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { X } from 'react-feather'
import Slider from './LeverageSlider'
import SwapAssetPanel from './SwapAssetPanel'

export const MIN_LEVERAGE = 1.1
export const MAX_LEVERAGE = 5
export const STEP_LEVERAGE = 0.05

interface LoopBoxProps {
  token: Token | undefined
  onTokenSelect: (token: Token | undefined) => void
}

const LoopBox = ({ token, onTokenSelect }: LoopBoxProps) => {
  const { chainId, account } = useActiveWeb3React()
  const AVAR_TOKENS = GET_AVAR_TOKEN(chainId)
  const { WRAPPED_NATIVE, SCULPT } = GET_TOKEN(chainId)
  const LOOPABLE_TOKEN_ADDRESS = GET_LOOPABLE(chainId)
  const stakeAddresses = GET(chainId)
  const spender = stakeAddresses.loopAddress

  // const [tokenState, setTokenState] = useState<Token | undefined>(initialToken)
  const [value, setValue] = useState('')
  const [leverage, setLeverage] = useState(MAX_LEVERAGE)
  const [buttonText, setButtonText] = useState('...')
  const [confirming, setConfirming] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const { useUserAccountData, useUserReserveData, useReservesData } = useReserve()
  const reservesData = useReservesData()
  const reserve = useMemo(() => {
    return reservesData.find((d) => {
      const underlyingToken = AVAR_TOKENS[d.underlyingTokenAddress]
      return underlyingToken.address.toLowerCase() === token?.address.toLowerCase()
    })
  }, [AVAR_TOKENS, reservesData, token?.address])

  // const lockdrops = GET_LOCK_DROP(chainId)
  const loopableCurrencies = useMemo(() => {
    return reservesData.reduce((addresses, r) => {
      if (
        r.underlyingTokenAddress.toLowerCase() !== WRAPPED_NATIVE.address.toLowerCase() &&
        LOOPABLE_TOKEN_ADDRESS.includes(r.underlyingTokenAddress)
      ) {
        addresses.push(r.underlyingTokenAddress)
      }
      return addresses
    }, [] as string[])
  }, [reservesData, WRAPPED_NATIVE, LOOPABLE_TOKEN_ADDRESS])

  const amount: CurrencyAmount<Token> | undefined = useMemo(() => {
    return tryParseAmount(value, token)
  }, [value, token])

  const { useApproveDelegatorCallback } = useBorrow()
  const varToken = AVAR_TOKENS[reserve?.variableDebtTokenAddress ?? '']
  const [approvalState, approve] = useApproveCallback(amount, spender)
  const [approvalStateVar, approveVar] = useApproveDelegatorCallback(varToken, amount, spender)

  const { loop, isLooping, useCalculateCollateralBorrow } = useLoop(spender)

  useEffect(() => {
    if (!isLooping && submitted) {
      setValue('')
      setConfirming(false)
      setSubmitted(false)
    }
  }, [isLooping, submitted])

  // const { exchangeName, addLiquidityUrl } = stakeAddresses
  const balance = useCurrencyBalance(account ?? undefined, token)

  useEffect(() => {
    if (approvalState === ApprovalState.NOT_APPROVED) {
      setButtonText('Approve')
    } else if (approvalState === ApprovalState.PENDING) {
      setButtonText('Approving...')
    } else if (approvalStateVar === ApprovalState.NOT_APPROVED) {
      setButtonText('Approve Delegator')
    } else if (approvalStateVar === ApprovalState.PENDING) {
      setButtonText('Delegator Approving...')
    } else if (approvalState === ApprovalState.APPROVED && approvalStateVar === ApprovalState.APPROVED) {
      setButtonText('Start Looping')
    }

    if (value === '') {
      setButtonText('Input Amount')
    }

    if (isLooping) {
      setButtonText('Looping...')
    }
  }, [approvalState, approvalStateVar, isLooping, value])

  const handleLoop = useCallback(async () => {
    if (approvalState !== ApprovalState.APPROVED) {
      await approve()
    }
    if (approvalStateVar !== ApprovalState.APPROVED) {
      await approveVar()
    }
    setSubmitted(await loop(amount, leverage))
  }, [approvalState, approvalStateVar, amount, leverage, loop, approve, approveVar])

  const parsedLoopValue = useMemo(() => {
    return tryParseAmount(value, token)
  }, [value, token])
  const continueValid =
    (buttonText === 'Start Looping' || buttonText === 'Approve' || buttonText === 'Approve Delegator') &&
    !balance?.lessThan(parsedLoopValue ?? 0) &&
    parsedLoopValue?.greaterThan(0)

  const userReserveData = useUserReserveData(token?.address ?? '')
  const userAccountData = useUserAccountData(token)
  const healthFactor = currencyAmountToBigNumberJS(userAccountData?.healthFactor)

  const collateralBorrow = useCalculateCollateralBorrow(amount, leverage)
  const newCollateral = currencyAmountToBigNumberJS(collateralBorrow.newCollateral)
  const newBorrow = currencyAmountToBigNumberJS(collateralBorrow.newBorrow)

  const { useAVarTokenPriceDollar } = usePrice()
  const tokenPrice = useAVarTokenPriceDollar(token?.address ?? '')
  const newTotalCollateral = currencyAmountToBigNumberJS(userAccountData?.totalCollateralETH).plus(
    newCollateral.times(tokenPrice)
  )
  const newTotalBorrrow = currencyAmountToBigNumberJS(userAccountData?.totalDebtETH).plus(newBorrow.times(tokenPrice))
  const newHealthFactor = newTotalCollateral
    .times(userAccountData?.currentLiquidationThreshold ?? 0)
    .div(newTotalBorrrow)
    .div(1e4)
  const isTotalDebtETHZero = currencyAmountToBigNumberJS(userAccountData?.totalDebtETH).eq(0)

  const usageAsCollateralEnabled = userReserveData.usageAsCollateralEnabled

  const netApy: BigNumberJS = useMemo(() => {
    const depositApy = currencyAmountToBigNumberJS(reserve?.depositApy)
    const borrowApy = currencyAmountToBigNumberJS(reserve?.borrowApy)
    const amountIn = currencyAmountToBigNumberJS(amount)
    // (DepositAPR * newCollateralETH/amountIn) - (BorrowAPR * newBorrowETH/amountIn)

    return depositApy.times(newCollateral).minus(borrowApy.times(newBorrow)).div(amountIn)
  }, [reserve?.depositApy, reserve?.borrowApy, amount, newCollateral, newBorrow])

  const rewardApy: BigNumberJS = useMemo(() => {
    const depositIncentiveApy = currencyAmountToBigNumberJS(reserve?.depositIncentiveApy)
    const borrowIncentiveApy = currencyAmountToBigNumberJS(reserve?.borrowIncentiveApy)
    const amountIn = currencyAmountToBigNumberJS(amount)
    // (IncentiveDepositAPR * newCollateralETH/amountIn) + (IncentiveBorrowAPR * newBorrowETH/amountIn)

    return depositIncentiveApy.times(newCollateral).plus(borrowIncentiveApy.times(newBorrow)).div(amountIn)
  }, [reserve?.depositIncentiveApy, reserve?.borrowIncentiveApy, amount, newCollateral, newBorrow])

  const estimatedNetApy: BigNumberJS = useMemo(() => {
    return rewardApy.minus(netApy)
  }, [netApy, rewardApy])

  const handleOnLeverageChange = useCallback((value: number) => {
    setLeverage(value)
  }, [])

  const handleCurrencySelect = useCallback(
    (cur: Currency) => {
      const t = Object.values(AVAR_TOKENS).find((token) => {
        return token.symbol === cur?.symbol
      })
      onTokenSelect(t)
    },
    [onTokenSelect, AVAR_TOKENS]
  )

  useEffect(() => {
    if (!token) {
      if (loopableCurrencies.length > 0) {
        handleCurrencySelect(AVAR_TOKENS[loopableCurrencies[0]])
      }
    }
  }, [token, AVAR_TOKENS, loopableCurrencies, handleCurrencySelect])

  const borrowedBalance = useCurrencyBalance(account ?? undefined, varToken ?? undefined)

  const ltv = userAccountData?.ltv ?? new BigNumberJS(0)
  const totalCollateralETHDollar = userAccountData?.totalCollateralETH

  if (!chainId) return <></>

  return (
    <div className="flex flex-col w-full h-full gap-6 sm:gap-8">
      <Card className="border border-sculptor bg-dark-900/80 shadow-sculptor">
        <div className="flex flex-col gap-5">
          <div className="flex justify-between w-full">
            <Typography variant="base" className="flex gap-1">
              You already borrowed
            </Typography>
            <Typography variant="base" className="text-white">
              {format(currencyAmountToBigNumberJS(borrowedBalance))}{' '}
              <span className="text-secondary">{token?.symbol}</span>
            </Typography>
          </div>
          <div className="flex justify-between w-full">
            <Typography variant="base" className="flex gap-1">
              Total collateral
            </Typography>
            <Typography variant="base" className="text-white">
              $ {format(currencyAmountToBigNumberJS(totalCollateralETHDollar))}
            </Typography>
          </div>
          <div className="flex justify-between w-full">
            <Typography variant="base" className="flex gap-1">
              Loan to value
            </Typography>
            <Typography variant="base" className="text-white">
              {format(ltv)} %
            </Typography>
          </div>
          <div className="flex justify-between w-full">
            <Typography variant="base" className="flex gap-1">
              Health factor
            </Typography>
            <Typography variant="base" className={healthFactorColorClass(healthFactor)}>
              {isTotalDebtETHZero ? '-' : format(healthFactor)}
            </Typography>
          </div>
        </div>
      </Card>

      {!confirming ? (
        <Card className="border border-sculptor bg-dark-900/80 shadow-sculptor">
          <div className="flex items-center justify-between">
            <Typography variant="h3" weight={700} className="flex text-white">
              1-Click Loop
            </Typography>
          </div>

          <Typography variant="base" className="mt-5 mb-5">
            1-Click looping is an automated process that repeats the borrow and deposit cycle multiple times. Earn yield
            on a greater collateral value with up to {MAX_LEVERAGE}X leverage.
          </Typography>

          <SwapAssetPanel
            spendFromWallet={true}
            header={SwapAssetPanel.Header}
            currencies={loopableCurrencies}
            currency={token}
            value={value}
            onChange={(val) => setValue(val || '')}
            onSelect={(cur) => handleCurrencySelect(cur)}
          />

          <Slider
            min={MIN_LEVERAGE}
            max={MAX_LEVERAGE}
            step={STEP_LEVERAGE}
            value={leverage}
            onChange={handleOnLeverageChange}
            label={'Leverage'}
          />

          <div className="flex items-center justify-between mt-4">
            <Typography variant="base">Net APY</Typography>
            <Typography variant="base" className="text-white">
              {format(netApy, { average: true })} %
            </Typography>
          </div>

          <div className="flex items-center justify-between mt-4">
            <Typography variant="base">Reward APR</Typography>
            <Typography variant="base" className="text-white">
              {format(rewardApy, { average: true })} %
            </Typography>
          </div>

          <div className="flex items-center justify-between mt-4">
            <Typography variant="base">Estimated Net APY</Typography>
            <Typography variant="base" className="text-white">
              {format(estimatedNetApy, { average: true })} %
            </Typography>
          </div>

          <div className="flex items-center justify-between mt-4">
            <Typography variant="base">New Health Factor</Typography>
            <Typography variant="base" className={healthFactorColorClass(newHealthFactor)}>
              {format(newHealthFactor)}
            </Typography>
          </div>

          <Button className="mt-4" fullWidth disabled={!continueValid} onClick={() => setConfirming(true)}>
            Continue
          </Button>
        </Card>
      ) : (
        <Card className="border border-sculptor bg-dark-900/80 shadow-sculptor">
          <Typography variant="lg" className="flex items-center justify-between gap-2 text-white">
            Looping overview <X onClick={() => setConfirming(false)} className="cursor-pointer" />
          </Typography>
          <Typography variant="base" className="mt-8 mb-5">
            These are your transaction details. Make sure to check if this is correct before submitting.
          </Typography>
          <div className="flex flex-col w-full gap-4 p-4 border rounded border-dark-800">
            <div className="flex justify-between">
              <Typography variant="base">Amount</Typography>
              <Typography variant="base" className="flex items-center gap-1 text-white">
                <CurrencyLogo currency={token} size={16} /> {format(value, { showExact: true }) ?? '-'}{' '}
                <span className="text-secondary">{token?.symbol}</span>
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography variant="base">Collateral usage</Typography>
              {usageAsCollateralEnabled ? (
                <Typography variant="base" className="text-blue-500">
                  Yes
                </Typography>
              ) : (
                <Typography variant="base" className="text-red-500">
                  No
                </Typography>
              )}
            </div>
            <div className="flex justify-between">
              <Typography variant="base">New health factor</Typography>
              <Typography variant="base" className={healthFactorColorClass(newHealthFactor)}>
                {format(newHealthFactor)}
              </Typography>
            </div>
          </div>

          <Button
            disabled={approvalState === ApprovalState.PENDING || value === '' || isLooping} // removed: || newHealthFactor.lt(1)
            className="mt-4"
            fullWidth
            onClick={handleLoop}
          >
            {buttonText}
          </Button>
        </Card>
      )}
    </div>
  )
}

export default LoopBox
