export const LOOP_BORROW_RATIO: { [key: string]: number[] } = {
  '5': [8000, 17],
  '4.95': [7979, 17],
  '4.9': [7959, 17],
  '4.85': [7938, 16],
  '4.8': [7916, 16],
  '4.75': [7894, 16],
  '4.7': [7872, 16],
  '4.65': [7849, 15],
  '4.6': [7826, 15],
  '4.55': [7802, 15],
  '4.5': [7777, 15],
  '4.45': [7752, 14],
  '4.4': [7727, 14],
  '4.35': [7701, 14],
  '4.3': [7674, 14],
  '4.25': [7647, 13],
  '4.2': [7619, 13],
  '4.15': [7590, 13],
  '4.1': [7560, 13],
  '4.05': [7530, 13],
  '4': [7500, 12],
  '3.95': [7468, 12],
  '3.9': [7435, 12],
  '3.85': [7402, 12],
  '3.8': [7368, 11],
  '3.75': [7333, 11],
  '3.7': [7297, 11],
  '3.65': [7260, 11],
  '3.6': [7222, 11],
  '3.55': [7183, 10],
  '3.5': [7142, 10],
  '3.45': [7101, 10],
  '3.4': [7058, 10],
  '3.35': [7014, 9],
  '3.3': [6969, 9],
  '3.25': [6923, 9],
  '3.2': [6875, 9],
  '3.15': [6825, 9],
  '3.1': [6774, 8],
  '3.05': [6721, 8],
  '3': [6666, 8],
  '2.95': [6610, 8],
  '2.9': [6551, 7],
  '2.85': [6491, 7],
  '2.8': [6428, 7],
  '2.75': [6363, 7],
  '2.7': [6296, 7],
  '2.65': [6226, 6],
  '2.6': [6153, 6],
  '2.55': [6078, 6],
  '2.5': [6000, 6],
  '2.45': [5918, 6],
  '2.4': [5833, 5],
  '2.35': [5744, 5],
  '2.3': [5652, 5],
  '2.25': [5555, 5],
  '2.2': [5454, 5],
  '2.15': [5348, 4],
  '2.1': [5238, 4],
  '2.05': [5121, 4],
  '2': [5000, 4],
  '1.95': [4871, 4],
  '1.9': [4736, 3],
  '1.85': [4594, 3],
  '1.8': [4444, 3],
  '1.75': [4285, 3],
  '1.7': [4117, 3],
  '1.65': [3939, 3],
  '1.6': [3750, 2],
  '1.55': [3548, 2],
  '1.5': [3333, 2],
  '1.45': [3103, 2],
  '1.4': [2857, 2],
  '1.35': [2592, 2],
  '1.3': [2307, 2],
  '1.25': [2000, 2],
  '1.2': [1666, 2],
  '1.15': [1304, 2],
  '1.1': [909, 2],
}
