import { TransactionResponse } from '@ethersproject/abstract-provider'
import { Currency, CurrencyAmount, MaxUint256, Token } from '@sushiswap/core-sdk'
import { GET } from 'app/config/sculptor/stake'
import { GET_TOKEN } from 'app/config/tokens'
import { useActiveWeb3React } from 'app/services/web3'
import { useSingleCallResult } from 'app/state/multicall/hooks'
import { useHasPendingApproval, useIsTransactionPending, useTransactionAdder } from 'app/state/transactions/hooks'
import { useCallback, useMemo, useState } from 'react'

import { ApprovalState, useSculptorVarTokenContract } from '..'
import { useSculptorLendingPoolContract, useSculptorWETHGatewayContract } from '../useContract'
import useToastError from './useToastError'

function useBorrow() {
  const { showPopup } = useToastError()
  const { account, chainId } = useActiveWeb3React()
  const { WRAPPED_NATIVE } = GET_TOKEN(chainId)

  const addTransaction = useTransactionAdder()
  const lendingPoolContract = useSculptorLendingPoolContract()
  const wETHGatewayContract = useSculptorWETHGatewayContract()

  const [isBorrowingInner, setIsBorrowingInner] = useState<boolean>(false)
  const [isRepayingInner, setIsRepayingInner] = useState<boolean>(false)

  const [borrowTx, setBorrowTx] = useState<string | undefined>()
  const [repayTx, setRepayTx] = useState<string | undefined>()

  const isBorrowing = useIsTransactionPending(borrowTx) || isBorrowingInner
  const isRepaying = useIsTransactionPending(repayTx) || isRepayingInner

  const borrow = useCallback(
    async (currencyAmount: CurrencyAmount<Token> | undefined): Promise<boolean> => {
      if (currencyAmount && chainId) {
        try {
          setIsBorrowingInner(true)
          if (currencyAmount.currency.address.toLowerCase() !== WRAPPED_NATIVE.address.toLowerCase()) {
            const tx = await lendingPoolContract?.borrow(
              currencyAmount.currency.address,
              currencyAmount.quotient.toString(),
              2,
              0,
              account
            )
            setBorrowTx(tx.hash)
            addTransaction(tx, { summary: 'Borrowed' })
          } else {
            const tx = await wETHGatewayContract?.borrowETH(
              GET(chainId).lendingPoolAddress,
              currencyAmount.quotient.toString(),
              2,
              0
            )
            setBorrowTx(tx.hash)
            addTransaction(tx, { summary: 'Borrowed' })
          }
          return true
        } catch (e: any) {
          console.error('Borrowing error:', e)
          showPopup(e?.data?.message)
        } finally {
          setIsBorrowingInner(false)
        }
      }
      return false
    },
    [chainId, WRAPPED_NATIVE.address, lendingPoolContract, account, addTransaction, wETHGatewayContract, showPopup]
  )

  const repay = useCallback(
    async (currencyAmount: CurrencyAmount<Token> | undefined, isMax?: boolean): Promise<boolean> => {
      if (currencyAmount && chainId) {
        try {
          setIsRepayingInner(true)
          const adder = CurrencyAmount.fromRawAmount(currencyAmount.currency, isMax ? '100000000000' : '0')
          if (currencyAmount.currency.address.toLowerCase() !== WRAPPED_NATIVE.address.toLowerCase()) {
            const tx = await lendingPoolContract?.repay(
              currencyAmount.currency.address,
              currencyAmount.add(adder).quotient.toString(),
              2,
              account
            )
            setRepayTx(tx.hash)
            addTransaction(tx, { summary: 'Repaid' })
          } else {
            const tx = await wETHGatewayContract?.repayETH(
              GET(chainId).lendingPoolAddress,
              currencyAmount.add(adder).quotient.toString(),
              2,
              account,
              {
                value: currencyAmount.add(adder).quotient.toString(),
              }
            )
            setRepayTx(tx.hash)
            addTransaction(tx, { summary: 'Repaid' })
          }
          return true
        } catch (e: any) {
          console.error('Repaying error:', e)
          showPopup(e?.data?.message)
        } finally {
          setIsRepayingInner(false)
        }
      }
      return false
    },
    [chainId, WRAPPED_NATIVE.address, lendingPoolContract, account, addTransaction, wETHGatewayContract, showPopup]
  )

  const useTokenAllowanceDelegator = (
    token?: Token,
    owner?: string,
    spender?: string
  ): CurrencyAmount<Token> | undefined => {
    const contract = useSculptorVarTokenContract(token?.address, false)

    const inputs = useMemo(() => [owner, spender], [owner, spender])
    const allowance = useSingleCallResult(contract, 'borrowAllowance', inputs).result

    return useMemo(
      () => (token && allowance ? CurrencyAmount.fromRawAmount(token, allowance.toString()) : undefined),
      [token, allowance]
    )
  }

  const useApproveDelegatorCallback = (
    varToken?: Token,
    amountToApprove?: CurrencyAmount<Currency>,
    spender?: string
  ): [ApprovalState, () => Promise<void>] => {
    const { account } = useActiveWeb3React()
    const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

    const currentAllowance = useTokenAllowanceDelegator(varToken, account ?? undefined, spender)
    const pendingApproval = useHasPendingApproval(token?.address, spender)

    // check the current approval status
    const approvalState: ApprovalState = useMemo(() => {
      if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
      if (amountToApprove.currency.isNative) return ApprovalState.APPROVED
      // we might not have enough data to know whether or not we need to approve
      if (!currentAllowance) return ApprovalState.UNKNOWN

      // amountToApprove will be defined if currentAllowance is
      return currentAllowance.lessThan(amountToApprove)
        ? pendingApproval
          ? ApprovalState.PENDING
          : ApprovalState.NOT_APPROVED
        : ApprovalState.APPROVED
    }, [amountToApprove, currentAllowance, pendingApproval, spender])

    const tokenContract = useSculptorVarTokenContract(varToken?.address)
    const addTransaction = useTransactionAdder()

    const approve = useCallback(async (): Promise<void> => {
      if (approvalState !== ApprovalState.NOT_APPROVED) {
        console.error('approve was called unnecessarily')
        return
      }
      if (!token) {
        console.error('no token')
        return
      }

      if (!tokenContract) {
        console.error('tokenContract is null')
        return
      }

      if (!amountToApprove) {
        console.error('missing amount to approve')
        return
      }

      if (!spender) {
        console.error('no spender')
        return
      }

      let useExact = false

      return tokenContract
        .approveDelegation(spender, useExact ? amountToApprove.quotient.toString() : MaxUint256.toString())
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: 'Delegator Approve ' + amountToApprove.currency.symbol,
            approval: { tokenAddress: token.address, spender: spender },
          })
        })
        .catch((error: Error) => {
          console.debug('Failed to delgator approve token', error)
          throw error
        })
    }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction])

    return [approvalState, approve]
  }

  return { borrow, isBorrowing, repay, isRepaying, useApproveDelegatorCallback }
}

export default useBorrow
